import React from "react";
import "./style/mycerts.scss";
import msassociate from "./images/microsoft-certified-associate-badge.svg";
import msfundamentals from "./images/microsoft-certified-fundamentals-badge.svg";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

const Mycertifications = () => {
  const certs = [
    {
      name: "Power Platform Developer Associate",
      certlink:
        "https://learn.microsoft.com/api/credentials/share/en-us/KawalpreetSingh-6118/480F9508AE520813?sharingId=38A1D25B926EFD0B",
      image: msassociate,
      priority: "1",
    },
    {
      name: "Azure Fundamentals",
      certlink:
        "https://learn.microsoft.com/api/credentials/share/en-us/KawalpreetSingh-6118/177BEC26ADDB8EC1?sharingId=38A1D25B926EFD0B",
      image: msfundamentals,
      priority: "2",
    },
    {
      name: "Power Platform Fundamentals",
      certlink:
        "https://learn.microsoft.com/api/credentials/share/en-us/KawalpreetSingh-6118/209E9B41A19865E7?sharingId=38A1D25B926EFD0B",
      image: msfundamentals,
      priority: "3",
    },
    {
      name: "Microsoft 365 Fundamentals",
      certlink:
        "https://learn.microsoft.com/api/credentials/share/en-us/KawalpreetSingh-6118/862031912925DA86?sharingId=38A1D25B926EFD0B",
      image: msfundamentals,
      priority: "4",
    },
  ];

  const certcards = certs.map((data) => (
    <div className="cards-parent" key={data.priority}>
      {data.priority < "4" ? (
        <div className="cards" key={data.priority}>
          <img src={data.image} alt="" className="cert-images" />
          <span className="cert-title">{data.name}</span>
          <a href={data.certlink} target="_blank" className="cert-link" rel="noreferrer">
            View Details <OpenInNewIcon style={{ scale: "0.8", zIndex: "1" }} />
          </a>
        </div>
      ) : (
        ""
      )}
    </div>
  ));

  return (
    <div className="mycerts-parent">
      <div className="heading">My Certifications</div>
      <div className="cert-cards">{certcards}</div>
    </div>
  );
};

export default Mycertifications;
