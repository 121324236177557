import React from "react";
import "./style/navbar.scss";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import resume from "./images/resume.pdf";

const Navbar = () => {
  const expandMenu = () => {
    // var parent = document.querySelector(".nav-parent");
    var phonedis = document.querySelector(".phone-display");
    // var middle = parent.querySelector(".middle-content");
    // var right = parent.querySelector(".right-content");
    phonedis.classList.toggle("responsive-nav");
  };
  return (
    <>
      <div className="nav-parent">
        <div className="left-content">
          <h2 className="Nav-head">Personal portfolio</h2>
          <h4 className="Nav-head-pd">Personal portfolio</h4>
          <button className="hamburger-menu" onClick={expandMenu}>
            <MenuIcon fontSize="large" />
          </button>
        </div>
        <div className="middle-content">
          <div className="btns">
            <a href="#myskills" className="middle-button">
              Skills
            </a>
          </div>
          <div className="btns">
            <a href="#my-certs" className="middle-button">
              My Certifications
            </a>
          </div>
          <div className="btns">
            <a href="#aboutme" className="middle-button">
              About Me
            </a>
          </div>
          <div className="btns">
            <a href="#workexperience" className="middle-button">
              Work Experience
            </a>
          </div>
          <div className="btns">
            <a href="#contactme" className="middle-button">
              Contact Me
            </a>
          </div>
        </div>
        <div className="right-content">
          <div className="btn-download">
            <a href={resume} className="resume-download" download="Kawalpreet Singh">
              <DownloadOutlinedIcon /> Resume
            </a>
          </div>
        </div>
      </div>
      <div className="phone-display">
        <div className="btns-pd">
          <a href="#myskills" className="middle-button-pd" onClick={expandMenu}>
            Skills
          </a>
        </div>
        <div className="btns-pd">
          <a href="#my-certs" className="middle-button-pd" onClick={expandMenu}>
            My Certifications
          </a>
        </div>
        <div className="btns-pd">
          <a href="#aboutme" className="middle-button-pd" onClick={expandMenu}>
            About Me
          </a>
        </div>
        <div className="btns-pd">
          <a href="#workexperience" className="middle-button-pd" onClick={expandMenu}>
            Work Experience
          </a>
        </div>
        <div className="btns-pd">
          <a href="#contactme" className="middle-button-pd" onClick={expandMenu}>
            Contact Me
          </a>
        </div>
        <div className="btn-download-pd">
          <a href={resume} className="resume-download-pd" download="Kawalpreet Singh">
            <DownloadOutlinedIcon /> Resume
          </a>
        </div>
      </div>
    </>
  );
};

export default Navbar;
