import React from "react";
import Navbar from "./Navbar";
import AboutMe from "./AboutMe";
import MySkills from "./Myskills";
import Mycertifications from "./Mycertifications";
import MyDetails from "./Mydetails";
import MyExperience from "./MyExperience";
import Contactme from "./Contactme";
// import Footer from "./Footer";

const App = () => {
  return (
    <div
      className="app-parent"
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <div
        className="nav-main"
        style={{ position: "fixed", width: "100%", zIndex: "10000000000000" }}
      >
        <Navbar />
      </div>
      <div className="about-me-main">
        <AboutMe />
      </div>
      <div
        className="seprator"
        id="myskills"
        style={{
          width: "80%",
          height: "5px",
          backgroundColor: "black",
          marginTop: "2rem",
          marginBottom: "2rem",
        }}
      ></div>
      <div
        className="myskills-main"
        style={{ display: "flex", justifyContent: "center", width: "100%" }}
      >
        <MySkills />
      </div>
      <div
        className="seprator"
        id="my-certs"
        style={{
          width: "80%",
          height: "5px",
          backgroundColor: "black",
          marginTop: "2rem",
          marginBottom: "2rem",
        }}
      ></div>
      <div
        className="mycerts-main"
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          marginBlock: "50px",
        }}
      >
        <Mycertifications />
      </div>
      <div
        className="seprator"
        id="aboutme"
        style={{
          width: "80%",
          height: "5px",
          backgroundColor: "black",
          marginTop: "2rem",
          marginBottom: "2rem",
        }}
      ></div>
      <div
        className="my-details-main"
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          marginBlock: "50px",
        }}
      >
        <MyDetails />
      </div>
      <div
        className="seprator"
        id="workexperience"
        style={{
          width: "80%",
          height: "5px",
          backgroundColor: "black",
          marginTop: "2rem",
          marginBottom: "2rem",
        }}
      ></div>
      <div
        className="my-exp-main"
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          marginBlock: "50px",
        }}
      >
        <MyExperience />
      </div>
      <div
        className="seprator"
        id="contactme"
        style={{
          width: "80%",
          height: "5px",
          backgroundColor: "black",
          marginTop: "2rem",
          marginBottom: "2rem",
        }}
      ></div>
      <div
        className="contact-me-main"
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          marginBlock: "50px",
        }}
      >
        <Contactme />
      </div>
      {/* <div
        className="footer-main"
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          marginTop: "50px",
          backgroundColor: "black"
        }}
      >
        <Footer />
      </div> */}
    </div>
  );
};

export default App;
