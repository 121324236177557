import React from "react";
import "./style/myskills.scss";
import PowerPlatformIcon from "./images/PowerPlatform_scalable.svg";
import ReactIcon from "./images/reactjs.png";
import Javascript from "./images/java-script.png";
import Html from "./images/html.png";
import LinuxIcon from "./images/linux-platform.png";

const MySkills = () => {
  const myskills = [
    { name: "Power Platform", image: PowerPlatformIcon, id: 1 },
    { name: "ReactJS", image: ReactIcon, id: 2 },
    { name: "Javascript", image: Javascript, id: 3 },
    { name: "HTML", image: Html, id: 4 },
    { name: "Linux", image: LinuxIcon, id: 5 },
  ];

  const mycards = myskills.map((data) => (
    <div className="skill-cards" key={data.id}>
      <img src={data.image} alt="" className="skill-images" />
      <span className="skill-texts">{data.name}</span>
    </div>
  ));
  return (
    <>
      <div className="myskills-parent">
        <div className="skills-title">
          <span className="title-text">My Skills</span>
        </div>
        <div className="list-grid">
          {mycards}
        </div>
      </div>
    </>
  );
};

export default MySkills;
