import React from "react";
import "./style/myexp.scss";
import pentharalogo from "./images/Penthara-Logo.png";

const MyExperience = () => {

    const workdetails = [
        {title: "Penthara Technologies", post: "M365 Developer", time: "Aug 2023 - Present", img: pentharalogo,id: 1}
    ]

    const workcards = workdetails.map((data) => (
        <div className="workcards" key={data.id}>
            <img src={data.img} alt="" className="logo"/>
            <span className="post">{data.post}</span>
            <span className="time">{data.time}</span>
        </div>
    ))
    return (
        <div className="my-exp-parent">
            <div className="heading">
                My Experience
            </div>
            {workcards}
        </div>
    )
}


export default MyExperience;