import React from "react";
import "./style/aboutme.scss";
import amIMG from "./images/imageedit_2_2412908153.jpg";
import InstagramIcon from "@mui/icons-material/Instagram";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const AboutMe = () => {
  return (
    <div className="am-parent">
      <div className="am-content">
        <div className="am-left-column">
          <span className="am-text">
            Hello I'am <span className="bold-words"> Kawalpreet Singh.</span>
          </span>
          <br />
          <span className="am-text">
            <span className="bold-words">
              M365 Developer
            </span>
          </span>
          <br />
          <span className="am-text">
            Based in <span className="bold-words">India.</span>
          </span>
          <br />
          <span className="am-subtitle">
            Hi! here you will get to know about me and my acheivements. <br />
            This is my portfolio, although I am not an advanced web developer, I
            did learn HTML, CSS, JS and React to make this portfolio. I hope you
            like it. <br />
            Below are links to some of my social media profiles, feel free to
            check them out.
          </span>
          <br />
          <div className="sm-links">
            <a
              href="https://www.instagram.com/k._.singh21/?utm_source=qr&igsh=ZDRtOXA2dnZsMmQx"
              target="_blank"
              className="link-icons"
              rel="noreferrer"
            >
              <InstagramIcon className="icons" />
            </a>
            <a
              href="https://github.com/Ksingh-Fugalimpala"
              target="_blank"
              className="link-icons"
              rel="noreferrer"
            >
              <GitHubIcon className="icons" />
            </a>
            <a
              href="https://www.linkedin.com/in/kawalpreet-singh-108196250/"
              target="_blank"
              className="link-icons"
              rel="noreferrer"
            >
              <LinkedInIcon className="icons" />
            </a>
          </div>
        </div>

        <div className="am-right-column">
          <img src={amIMG} alt="" className="right-img" />
        </div>
      </div>
    </div>
  );
};

export default AboutMe;
