import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import "./style/contactme.scss";
import { serviceParams } from "./serviceemail";

const Contactme = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [message, setMessage] = useState("");

  const sendEmail = (e) => {
    e.preventDefault();

    const templateParams = {
      from_name: name,
      from_email: email,
      from_website: website,
      message: message,
      to_name: "it.singh.kawal@gmail.com",
    };

    {
      name && email
        ? emailjs
            .send(serviceParams.serviceId, serviceParams.tempalteId, templateParams, serviceParams.publicKey)
            .then((response) => {
              setName("");
              setMessage("");
              setEmail("");
              setWebsite("");
              window.alert("Email Sent. I will contact you soon.")
            })
        : window.alert("Name and Email both are required");
    }
  };
  return (
    <div className="contact-me-parent">
      <div className="contact-form">
        <div className="form">
          <form onSubmit={sendEmail}>
            <div className="inputs">
              <input
                type="text"
                className="your-name"
                value={name}
                placeholder="Your Name"
                onChange={(e) => setName(e.target.value)}
              />
              <input
                type="text"
                className="your-email"
                value={email}
                placeholder="Your Email"
                onChange={(e) => setEmail(e.target.value)}
              />
              <input
                type="text"
                className="your-website"
                value={website}
                placeholder="Your Website (optional)"
                onChange={(e) => setWebsite(e.target.value)}
              />
              <textarea
                className="your-description"
                value={message}
                cols="30"
                rows="10"
                placeholder="How can I help you ?"
                onChange={(e) => setMessage(e.target.value)}
              />
            </div>
            <button className="get-in-touch" type="submit">
              Get in Touch
            </button>
          </form>
        </div>
      </div>
      <div className="contact-details">
        <span className="title">Let's talk for something Special</span>
        <span className="sub-title">
          Contact me by filling the form or send me email or call using the
          following details.
        </span>
        <span className="email">it.singh.kawal@gmail.com</span>
        <span className="phone-number">+91-7840070087</span>
      </div>
    </div>
  );
};

export default Contactme;
