import React from "react";
import "./style/mydetails.scss";
const MyDetails = () => {
  return (
    <div className="my-details-parent">
      <div className="details-content">
        <span className="heading">
          About <span className="sub-heading">Me</span>
        </span>
        <p className="content-paragraphs">I am a <span style={{fontWeight: "900"}}>M365 developer at Penthara Technologies</span>, I develop custom apps and efficient solutions using Power Apps, Power Automate, and Power BI. I work with a team of Talented developers and designers.</p>
        <p className="content-paragraphs">I have a <span style={{fontWeight: "900"}}>Bachelor of Computer Application in computer science</span>. I do well in fast-changing environments, using my technical skills to solve problems and come up with new ideas. Beyond my technical expertise, I'm a collaborative team player with strong communication skills. I value teamwork, open dialogue, and constructive feedback, believing that collective efforts lead to greater achievements.</p>
      </div>
    </div>
  );
};

export default MyDetails;
